<template>
  <div class="cell-box">
    <div class="cell-left">
      {{ title }}：
    </div>
    <div class="cell-right">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CellItem",
  props: {
    title: String,
    content: String
  },
};
</script>

<style lang="scss" scoped>
.cell-box {
  overflow: hidden;
  margin-top: 17px;
  font-size: 14px;
  font-weight: 400;
  color: #4D4D4D;

  .cell-left {
    float: left;
    width: 100px;
    text-align: right;
    color: #9F9F9F;
  }

  .cell-right {
    // float: left;
    margin-left: 100px;
    // width: 70%;
  }
}
</style>