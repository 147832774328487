<template>
    <div>
        <van-popup v-model="showSign" round :close-on-click-overlay='false'>
            <div class="success-box">
                <div class="suc-img"></div>
                已签到
                <div class="suc-btn" @click="linkUrl">我知道了</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { Popup } from "vant";
export default {
    name: "HelloWorld",
    props: {
    },
    data() {
        return {
            showSign:false
        }
    },
    methods: {
        open(){
            this.showSign = true
        },
        // 跳转
        linkUrl(){
            this.showSign = false
            this.$router.push({ path: "/index" });
        }
    },
    components: {
        [Popup.name]: Popup,
    },
};
</script>

<style scoped lang="scss">
.success-box{
    margin: 12px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    color: #31CD73;
    padding: 26px 0 20px;
    text-align: center;
    .suc-img{
        width: 54px;
        height: 54px;
        margin: 0 auto 10px;
        font-size: 0;
        background: url('../assets/sign-img.png') no-repeat;
        background-size: 100% 100%;
    }
    .suc-btn{
      width: 243px;
      height: 39px;
      line-height: 39px;
      margin-top: 30px;
      color: #fff;
      background: linear-gradient(14deg, #526EFF, #6384FF);
      border-radius: 25px;

      font-size: 16px;
      font-weight: 400;
    }
}
</style>
