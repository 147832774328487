<template>
  <div class="receipt">
    <DetailItem :detail='detail'></DetailItem>

    <SucPopup ref='sPop'></SucPopup>

    <div class="btn-box" v-if='detail.receiptStatus == 1 && detail.signInStatus == 0'>
      <div class="btn-inner">
        <div class="btn-item" @click="setSignInStatus(1)">立即签到</div>
      </div>
    </div>
  </div>
</template>

<script>
import { button, Toast, NavBar, Cell, CellGroup } from "vant";
import Cookies from "js-cookie";
import SucPopup from "@/components/SucPopup";
import DetailItem from "@/components/DetailItem";
export default {
  name: "index",
  data() {
    return {
      detail:JSON.parse(this.$route.query.detail),
      showSign: false,
    };
  },
  methods: {
    // 签到
    async setSignInStatus(signInStatus) {
      let params = {
        signInStatus: signInStatus,
        conferenceUserId:this.detail.id,
      };
      await this.$post(`/h5/weChat/setSignInStatus`, params).then(
        (res) => {
          if (res.code == "0") {
              this.$refs.sPop.open()
          }else{
            Toast(res.message);
          }
        }
      );
    },
  
  },
  mounted() {
  },
  components: {
    [Toast.name]: Toast,
    [button.name]: button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    SucPopup,
    DetailItem,
  },
};
</script>

<style lang="scss" scoped>
.receipt {
  background: #f4f4f4;
  min-height: 100vh;
}
.btn-box {
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 100%;
  .btn-inner {
    display: flex;
    margin: 12px;

    .btn-item {
      flex: 1;
      font-size: 16px;
      color: #ffffff;
      background: linear-gradient(0deg, #526eff 0%, #6f8eff 100%);
      border-radius: 25px;
      // margin: 34rpx 30rpx;
      line-height: 50px;
      text-align: center;
    }
  
  }
}
</style>